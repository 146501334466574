import gql from "graphql-tag";

export default gql`

  fragment RoomTypeFragment on RoomType {
    id
    type
    canBeSelectedInProposition
    labelFr
    labelEn
    descImgUrls
    imgPictureFileIds
    descCapacityFr
    descTextFr
    descCapacityEn
    descTextEn
  }

`