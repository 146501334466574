import React, { useContext, useState } from "react"
import { useQuery, useSubscription } from "@apollo/react-hooks"

// import tokenGetGql from "graphql/BILLING/Token/query/tokenGet.gql"
// import tokenUpdateGql from "graphql/BILLING/Token/subscription/tokenUpdate.gql"
// import tokenAddGql from 'graphql/BILLING/Token/mutation/tokenAdd.gql';



// import "./style.scss"
import { TokenStatus } from "../Status/TokenStatus";
import { ClickAndCopy } from "components/common/Form/ClickAndCopy";
// import { TokenCaptureConfirm } from "../Capture/Confirm/TokenCaptureConfirm";
import { userContext } from "context/User";
import { Terminal, Token, TokenSendStatusEnum, TokenStatusEnum, TokenTypeEnum, User, UserRoleEnum } from "model";
// import { TokenRefundConfirm } from "../Refund/Confirm/TokenRefundConfirm";
import { getRootUrl } from "helpers/Utils";
// import { TokenTypeInput } from "../Type/Form/TokenTypeInput";
import { TokenSend } from "../Send/TokenSend";
import { TokenSendStatus } from "../Send/Status/TokenSendStatus";
import { Modal, SelectInput } from "@zipou/front_tools";
import tokenUpdateGql from "graphql/BILLING/Token/subscription/tokenUpdate.gql";
import tokenGetGql from "graphql/BILLING/Token/query/tokenGet.gql";
import { ChargeAdd } from "../../Charge/Add/ChargeAdd";
import { ChargeListByToken } from "../../Charge/List/ChargeListByToken";
import { ContactDetails } from "components/front/PMS/Contact/Details/ContactDetails";
import { TokenDelete } from "../Delete/TokenDelete";
import { TerminalStatus } from "../../Terminal/Status/TerminalStatus";
import { TerminalProcessToken } from "../../Terminal/Actions/TerminalProcessToken";
import { TokenType } from "../Type/TokenType";
import terminalListGql from "graphql/BILLING/Terminal/query/terminalList.gql";
// import {TokenCaptureStatus} from "../Capture/Status/CaptureStatus";

type TokenDisplayProps = {
  tokenId?: string,
}

export const TokenDisplay = (props: TokenDisplayProps) => {

  const user = useContext<User>(userContext)


  const [terminalId, updateTerminalId] = useState<string>()

  const [displaySend, updateDisplaySend] = useState<boolean>(false)
  const [displayDelete, updateDisplayDelete] = useState<boolean>(false)
  const [displayCharge, updateDisplayCharge] = useState<boolean>(false)

  const { data, refetch } = useQuery<{ token: Token }>(tokenGetGql, {
    variables: {
      id: props?.tokenId,
    },
    onCompleted: (data) => {
      updateTerminalId(data?.token?.terminalId)
    },
    skip: !props?.tokenId,
  })
  const token = data?.token

  const { data: terminalData } = useQuery<{ list: Terminal[] }>(terminalListGql, {
    variables: {
      hotelId: token?.hotelId,
    },
    skip: !token?.hotelId,
  })
  const terminalList = terminalData?.list

  useSubscription<{ token: Token }>(tokenUpdateGql, {
    variables: {
      id: props?.tokenId,
    },
    skip: !props?.tokenId,
  })




  const hasTemplate = token?.Hotel?.BillingConfig?.templateId
  const isSendable = (hasTemplate) && user?.roles?.includes(UserRoleEnum.ROLE_BILLING_EMAIL) &&
    token.sendStatus !== TokenSendStatusEnum.SEND_STATUS_SENT &&
    token.sendStatus !== TokenSendStatusEnum.SEND_STATUS_DELIVERED &&
    token.sendStatus !== TokenSendStatusEnum.SEND_STATUS_READ &&
    (token.status === TokenStatusEnum.STATUS_INITIAL || token.status === TokenStatusEnum.STATUS_PENDING_CUSTOMER)

  const isReSendable = (hasTemplate) && user?.roles?.includes(UserRoleEnum.ROLE_BILLING_EMAIL) && token.sendStatus === TokenSendStatusEnum.SEND_STATUS_SENT
  const isChargeable = token?.status == TokenStatusEnum.STATUS_DONE
  const isDeleteable = token?.status == TokenStatusEnum.STATUS_DONE || TokenStatusEnum.STATUS_INITIAL || TokenStatusEnum.STATUS_PENDING_CUSTOMER
  const isTypeLink = token?.type === TokenTypeEnum.TYPE_LINK
  const isTypeTerminal = token?.type === TokenTypeEnum.TYPE_TERMINAL

  const displayTerminalInput = (isTypeTerminal) && token?.status !== TokenStatusEnum.STATUS_DONE
  const displayTerminalAction = (isTypeTerminal && terminalId) && token?.status !== TokenStatusEnum.STATUS_DONE
  const displayTerminal = displayTerminalInput || displayTerminalAction

  const hasSendStatus = token?.sendStatus && token?.sendStatus !== TokenSendStatusEnum.SEND_STATUS_IDLE
  const hasDebug = user?.roles?.includes(UserRoleEnum.ROLE_DEBUG)
  const hasRolePMS = user?.roles?.includes(UserRoleEnum.ROLE_PMS)

  const hasNotes = token?.notes
  const hasInfos = hasNotes || false

  const chargeList = token?.Charge
  const hasCharges = chargeList?.length > 0

  const publicUrl = `${getRootUrl().slice(0, -1)}${token?.publicPath}`
  const showUrl = token?.status == TokenStatusEnum.STATUS_INITIAL || token?.status == TokenStatusEnum.STATUS_PENDING_CUSTOMER
  const showReceipt = token?.status == TokenStatusEnum.STATUS_DONE && !!token?.receiptInfo
  const hasActions = isChargeable || isSendable || isReSendable

  return <div className="token-container">
    <Modal display={displayCharge} onClose={() => updateDisplayCharge(false)}>
      <ChargeAdd tokenId={token?.id} onDone={() => {
        refetch()
        updateDisplayCharge(false)
      }} />
    </Modal>
    <Modal display={displaySend} onClose={() => updateDisplaySend(false)}>
      <TokenSend tokenId={token?.id} onDone={() => {
        refetch()
        updateDisplaySend(false)
      }} />
    </Modal>
    <Modal display={displayDelete}>
      <TokenDelete token={token} onDone={() => {
        refetch()
        updateDisplayDelete(false)
      }} />
    </Modal>
    <div className="row">
      <div className="col-12">


        <table className="table table-bordered">
          <tbody>
            {hasDebug && <tr>
              <th>Id</th>
              <td>{token?.id}</td>
            </tr>}
            <tr>
              <th>Cardex</th>
              <td>
                <ContactDetails contact={token?.Contact} />
              </td>
            </tr>
            {isTypeLink && <tr>
              <th>Lien </th>
              <td>

                <div className="input-group">
                  <span className="input-group-text">Lien Français</span>
                  <span className="form-control">{publicUrl}</span>
                  <ClickAndCopy value={publicUrl} />
                </div>

                <div className="input-group">
                  <span className="input-group-text">Lien Anglais</span>
                  <span className="form-control">{publicUrl}?lang=en</span>
                  <ClickAndCopy value={`${publicUrl}?lang=en`} />
                </div>

              </td>
            </tr>}
            {displayTerminal && <tr>
              <th>Terminal </th>
              <td>
                <table className="table table-bordered">
                  {displayTerminalInput && <tr>
                    <td>
                      Terminal
                    </td>
                    <td>
                      <SelectInput label="Choisissez un Terminal" id="terminalId" value={terminalId} errors={{}} choiceList={terminalList?.map(terminal => ({ id: terminal?.id, label: terminal?.name }))} onChange={terminalId => {
                        updateTerminalId(terminalId)
                      }} />
                    </td>
                  </tr>}
                  {displayTerminalAction && <>
                    <tr>
                      <td>
                        Status
                      </td>
                      <td>
                        <TerminalStatus terminalId={terminalId} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Action
                      </td>
                      <td>
                        <TerminalProcessToken tokenId={token?.id} terminalId={terminalId} />
                      </td>
                    </tr>
                  </>}
                </table>
              </td>
            </tr>}
            {hasInfos && <tr>
              <th>Infos</th>
              <td>
                <div className="input-group">
                  <span className="input-group-text">Notes</span>
                  <span className="form-control">{token?.notes}</span>
                </div>
              </td>
            </tr>}
            <tr>
              <th>Type</th>
              <td>
                <TokenType type={token?.type as TokenTypeEnum} />
              </td>
            </tr>
            <tr>
              <th>Statut</th>
              <td>
                <TokenStatus status={token?.status as TokenStatusEnum} />
              </td>
            </tr>
            {hasSendStatus && <tr>
              <th>Statut Envoi</th>
              <td>
                <TokenSendStatus status={token?.sendStatus as TokenSendStatusEnum} />
              </td>
            </tr>}
            {showReceipt && <tr>
              <th>Reçu</th>
              <td>
                <div className="input-group">
                  <span className="input-group-text">Type</span>
                  <span className="form-control">{token?.receiptInfo?.brand}</span>
                </div>

                <div className="input-group">
                  <span className="input-group-text">N° Carte</span>
                  <span className="form-control">XXXX-XXXX-XXXX-{token?.receiptInfo?.last4}</span>
                </div>

                <div className="input-group">
                  <span className="input-group-text">Expiration</span>
                  <span className="form-control">{token?.receiptInfo?.expMonth}/{token?.receiptInfo?.expYear}</span>
                </div>
              </td>
            </tr>}
            <tr>
              <th>Paiements Associés</th>
              <td>
                <ChargeListByToken chargeList={chargeList} />
              </td>
            </tr>
            {hasActions && <tr>
              <th>Actions</th>
              <td>
                {isSendable && <button className="btn btn-sm btn-primary" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplaySend(true)}>
                  Envoyer par Mail
                </button>}
                {isReSendable && <button className="btn btn-sm btn-warning" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplaySend(true)}>
                  Renvoyer par Mail
                </button>}
                {isChargeable && <button className="btn btn-sm btn-warning" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplayCharge(true)}>
                  Charger le client
                </button>}
                {isDeleteable && <button className="btn btn-sm btn-danger" type="button" style={{ marginLeft: 10 }} onClick={() => updateDisplayDelete(true)}>
                  Supprimer l'empreinte
                </button>}
              </td>
            </tr>}

          </tbody>
        </table>
      </div>
    </div>

  </div >

}

