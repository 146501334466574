import React, { useContext, useState } from "react"
import confirmationListQuery from "graphql/PMS/Confirmation/query/confirmationList"
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks"
import { ConfirmationStatus } from "../Status/ConfirmationStatus"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"

import './style.scss'
import { ReservationFullDetails } from "../../Reservation/Details/ReservationFullDetails"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { ConfirmationAnswer } from "../Answer/ConfirmationAnswer"
import confirmationSendManualByIdMutation from "graphql/PMS/Confirmation/mutation/confirmationSendManualById"
import confirmationDeleteByIdMutation from "graphql/PMS/Confirmation/mutation/confirmationDeleteById"
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton"
import { HotelBadge } from "../../Hotel/Badge/HotelBadge"
import { ConfirmationStatusFilter } from "../Status/ConfirmationStatusFilter"
import { TextInput } from "components/common/Form/TextInput"
import { userContext } from "context/User"
import { ContactEditButton } from "../../Contact/Widget/ContactEditButton"
import confirmationUpdateGql from "graphql/PMS/Confirmation/subscription/confirmationUpdate.gql"
import { ConfirmationPreview } from "../Preview/ConfirmationPreview"
import confirmationAnswerClearGql from "graphql/PMS/Confirmation/Answer/mutation/confirmationAnswerClear.gql"
// import { Tooltip } from "components/common/Navigation/Tooltip/Tooltip"
import confirmationQuestionGetByConfirmationId from "graphql/PMS/Confirmation/Question/query/confirmationQuestionGetByConfirmationId"
import { Confirmation, ConfirmationTypeEnum } from "model"
import { ReservationButton } from "../../Reservation/Button/ReservationButton"
import { ReservationGroupDisplayButton } from "../../Reservation/Group/Display/ReservationGroupDisplayButton"
import { ConfirmationDataForm } from "../Data/Form/ConfirmationDataForm"
import { ConfirmationDataEdit } from "../Data/Edit/ConfirmationDataEdit"
import { Tooltip } from "components/front/APP/Navigation/Tooltip"
import { Modal } from "@zipou/front_tools"
// import {OverlayTrigger, Tooltip} from "react-bootstrap"

export const ConfirmationList = () => {

  const limit = 20;

  const user = useContext(userContext)
  const [reservationFocusId, updateReservationFocusId] = useState(null)
  const [focusPmsId, updateFocusPmsId] = useState(null)
  const [focusHotelIds, updateFocusHotelIds] = useState(null)
  const [confirmationEdit, updateConfirmationEdit] = useState(null)
  const [confirmationEditData, updateConfirmationEditData] = useState<Confirmation>(null)
  const [confirmationDisplay, updateConfirmationDisplay] = useState(null)

  const [statusFilter, updateStatusFilter] = useState<string[]>(["CONFIRMATION_STATUS_TO_ANSWER", "CONFIRMATION_STATUS_TO_VALID", "CONFIRMATION_STATUS_TO_SEND", "CONFIRMATION_STATUS_SENT", "CONFIRMATION_STATUS_ERROR", "CONFIRMATION_STATUS_DELIVERED", "CONFIRMATION_STATUS_READ"])
  const [focusStatus, updateFocusStatus] = useState<any>(false)
  const [focusContactString, updateFocusContactString] = useState<boolean>(false)
  const [contactString, updateContactString] = useState<string | null>(null)
  const [pmsIdFilter, updatePmsIdFilter] = useState<any>(null)
  const [hotelIdsFilter, updateHotelIdsFilter] = useState<any>(user?.hotels)

  const isMulti = user?.hotels?.length > 1

  useSubscription(confirmationUpdateGql)

  const { data, refetch, loading } = useQuery(confirmationListQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      cursor: {
        page: 1,
        limit,
      },
      input: {
        orderField: "confirmation.creationDate",
        orderDirection: "DESC",
        status: statusFilter,
        pmsId: pmsIdFilter,
        hotelIdsList: hotelIdsFilter || [],
        contactString,
      }
    }
  })


  const [mutate] = useMutation(confirmationSendManualByIdMutation)
  const [mutateClear] = useMutation(confirmationAnswerClearGql)
  const [deleteMutate] = useMutation(confirmationDeleteByIdMutation, {
    refetchQueries: [{
      query: confirmationListQuery,
      variables: {
        cursor: {
          limit,
          page: data?.list.cursor?.page,
        }
      }
    }]
  })

  const clearAnswerByConfirmationId = (id: string) => {
    mutateClear({
      variables: {
        confirmationId: id,
      },
      refetchQueries: [{
        query: confirmationQuestionGetByConfirmationId,
        variables: {
          confirmationId: id,
        }
      }]
    })

  }

  const sendManualById = (id: string) => {
    mutate({
      variables: {
        id,
      }
    })
      .then(() => {
        updateConfirmationDisplay(null)
      })
  }

  const deleteById = (id: string) => {
    deleteMutate({
      variables: {
        id,
      }
    })
  }

  const confirmationList = data?.list

  const { nodes, cursor } = confirmationList || {}
  const hasResults = nodes?.length > 0

  return <div className="confirmation-list">

    <Modal display={!!focusStatus} onClose={() => updateFocusStatus(false)}>
      <ConfirmationStatusFilter value={statusFilter} onChange={(value: any) => {
        updateStatusFilter(value)
      }} />
    </Modal>
    <Modal display={!!focusContactString} onClose={() => updateFocusContactString(false)}>
      <TextInput value={contactString || ""} label="Chercher par nom de famille" onChange={(_, v) => {
        updateContactString(v)
      }} rightLabel={<span onClick={() => updateContactString(null)}>
        <span className="icon-trash"></span>
      </span>} />
    </Modal>
    <Modal display={!!focusHotelIds} onClose={() => updateFocusHotelIds(null)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateHotelIdsFilter([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateHotelIdsFilter(user?.Hotel?.map((h: any) => h.id))} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = hotelIdsFilter?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateHotelIdsFilter(e.target.checked ? [...(hotelIdsFilter || []), hotel?.id] : hotelIdsFilter?.filter((el: any) => el !== hotel?.id))}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>
    <Modal display={!!reservationFocusId} onClose={() => updateReservationFocusId(null)}>
      <ReservationFullDetails reservationId={reservationFocusId} />
    </Modal>
    <Modal display={!!focusPmsId} onClose={() => updateFocusPmsId(null)}>
      <TextInput label="Numero de Résa" errors={null} value={pmsIdFilter} onChange={(e, v) => {
        updatePmsIdFilter(v)
      }} rightLabel={<span onClick={() => updatePmsIdFilter(null)}>
        <span className="icon-trash"></span>
      </span>} />
    </Modal>

    <Modal display={!!confirmationDisplay} onClose={() => updateConfirmationDisplay(null)}>
      <ConfirmationPreview confirmationId={confirmationDisplay?.id} onSend={() => sendManualById(confirmationDisplay?.id)} />
    </Modal>
    <Modal display={!!confirmationEdit} onClose={() => updateConfirmationEdit(null)}>
      <ConfirmationAnswer confirmationId={confirmationEdit?.id} onDone={() => {
        updateConfirmationDisplay(confirmationEdit);
        updateConfirmationEdit(null)
      }} />
    </Modal>
    <Modal display={!!confirmationEditData} onClose={() => updateConfirmationEditData(null)}>
      <ConfirmationDataEdit confirmationId={confirmationEditData?.id} onDone={() => {
        updateConfirmationDisplay(confirmationEditData);
        updateConfirmationEditData(null)
      }} />
    </Modal>
    {(loading) && <div className="position-fixed" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div className="alert alert-warning">Chargement...</div>
    </div>}
    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            {isMulti && <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusHotelIds(true)
              }}>
                Hotel
              </button>
            </th>}
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusPmsId(true)
              }}>
                {pmsIdFilter && <span className="icon-flag mr-2" style={{ fontSize: 10 }} />}
                Numéro de résa
              </button>
            </th>
            <th className="text-white">

              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusContactString(true)
              }}>
                {contactString && <span className="icon-flag mr-2" style={{ fontSize: 10 }} />}
                Contact
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "confirmation.creationDate",
                  },
                })
              }}>
                Date de Création {data?.list?.input?.orderField === "confirmation.creationDate" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{statusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {(!hasResults && !loading) && <tr>
            <td colSpan={isMulti ? 6 : 5}><div className="alert alert-primary">Rien à afficher</div></td>
          </tr>}
          {hasResults && nodes?.map((confirmation: Confirmation) => {
            const { Hotel, Reservation, ReservationGroup, creationDate } = confirmation

            const isEditable = confirmation?.type === ConfirmationTypeEnum.CONFIRMATION_TYPE_QUESTION && confirmation?.status === "CONFIRMATION_STATUS_TO_ANSWER"
            const isSendable = true
            const isDeleteable = true
            const canBeCleared = confirmation?.type === "CONFIRMATION_TYPE_QUESTION" && confirmation?.status !== "CONFIRMATION_STATUS_TO_ANSWER"

            const isDataEditable = confirmation?.type === ConfirmationTypeEnum.CONFIRMATION_TYPE_DATA

            const isGroup = !!ReservationGroup

            return <tr key={`confirmation_${confirmation?.id}`}>
              {isMulti && <td><HotelBadge hotel={Hotel} /></td>}
              {!isGroup && <td>
                {/* <button className="ml-1 btn btn-sm btn-dark" onClick={() => updateReservationFocusId(Reservation?.id)}>{Reservation?.pmsId}</button> */}
                <ReservationButton reservationId={Reservation?.id} pmsId={Reservation?.pmsId} status={Reservation?.status} />
              </td>}
              {isGroup && <td>
                <ReservationGroupDisplayButton reservationGroupId={confirmation?.reservationGroupId} onDone={() => {
                  refetch({
                    ...data?.list?.cursor,
                  })
                }} />
              </td>}
              <td>
                <ContactEditButton contact={confirmation?.Contact} />
                {/* <ContactDisplay contact={confirmation?.Contact} /> */}
              </td>
              <td>{creationDate && <DateTimeFormat value={new Date(creationDate)} />}</td>
              <td><ConfirmationStatus status={confirmation?.status} /></td>
              <td className="">
                <div className="actions">
                  {(isSendable && !isEditable) && <Tooltip label="Verifier et envoyer"><button className="btn btn-warning btn-sm" style={{ marginLeft: 5 }} onClick={() => updateConfirmationDisplay(confirmation)}><span className="icon-search " /></button></Tooltip>}
                  {isEditable && <Tooltip label="Répondre aux questions"><button className="btn btn-warning btn-sm" style={{ marginLeft: 5 }} onClick={() => updateConfirmationEdit(confirmation)}><span className="icon-edit " /></button></Tooltip>}
                  {isDataEditable && <Tooltip label="Répondre aux questions"><button className="btn btn-warning btn-sm" style={{ marginLeft: 5 }} onClick={() => updateConfirmationEditData(confirmation)}><span className="icon-edit " /></button></Tooltip>}
                  {canBeCleared && <Tooltip label="Effacer les réponses">
                    <button className="btn btn-sm btn-danger" style={{ marginLeft: 5 }} onClick={() => clearAnswerByConfirmationId(confirmation?.id)} >
                      <span className="icon-rewind"></span>
                    </button>
                  </Tooltip>
                  }
                  {isDeleteable && <Tooltip label="Supprimer la confirmation">
                    <TemporisationButton seconds={5} onClick={() => deleteById(confirmation?.id)}>
                      {(onClick, spinnerComponent) => <button className="btn btn-sm btn-danger ml-1" onClick={onClick}>
                        {spinnerComponent}
                        <span className="icon-trash" />
                      </button>}
                    </TemporisationButton>
                  </Tooltip>}
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit,
          }
        })
      }} />
    </div>
  </div >
}