
import { Modal } from "@zipou/front_tools"
import { hotelContext } from "context/hotelContext"
import { Hotel, RoomType } from "model"
import React, { useContext, useState } from "react"
import { Carousel, CarouselItem } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

export type RoomTypeDisplayProps = {
  roomType: RoomType,
}


export const RoomTypeDisplay = (props: RoomTypeDisplayProps) => {

  const roomType = props?.roomType
  const hasManyImgUrl = roomType?.descImgUrls?.length > 1
  const [focusModal, updateFocusModal] = useState<any>(null)
  const [focusDesc, updateFocusDesc] = useState<any>(null)

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'proposition' });
  const isFrench = i18n.language === "fr";

  const hotel = useContext<Hotel>(hotelContext)
  const displayRoomDisclosure = hotel?.displayRoomPictureDisclosure

  // console.log("HOTEL CTX", hotel)

  return <div>

    <Modal display={!!focusModal} onClose={() => updateFocusModal(false)}>
      <TransformWrapper >
        <TransformComponent>
          <img src={focusModal} className="img-fluid" alt='loremipsum' />
        </TransformComponent>
      </TransformWrapper>
    </Modal>

    <Modal display={!!focusDesc} title="Infos" onClose={() => updateFocusDesc(false)}>
      <p className="text-dark">{isFrench ? roomType?.descTextFr : roomType.descTextEn}</p>
      <span className="text-dark">{isFrench ? roomType?.descCapacityFr : roomType?.descCapacityEn}</span>
    </Modal>

    <div className="card">
      {hasManyImgUrl ? <div>
        <Carousel interval={null} indicators={false} prevIcon={<span style={{ color: "#CCC", fontSize: 36 }} className="icon-carousel icon-chevron-left" />} nextIcon={<span style={{ color: "#CCC", fontSize: 36 }} className="icon-carousel icon-chevron-right" />}>
          {roomType?.descImgUrls?.map((url, index) => <CarouselItem key={`Url_${index}`}>
            <div onClick={() => updateFocusModal(url)}>
              <img src={url} className="img-fluid" alt='loremipsum' />
            </div>
          </CarouselItem>)}
        </Carousel>
        {displayRoomDisclosure && <div style={{ position: "relative" }}>
          <span className="legend" style={{ position: "absolute", bottom: 5, fontSize: 11, color: "white" }}>Photos non contractuelles</span>
        </div>}
      </div>
        : <div>
          <img src={roomType?.descImgUrls && roomType?.descImgUrls[0]} className="img-fluid" alt='loremipsum' />
        </div>
      }
    </div>

    <div style={{ padding: 10 }}>
      <span className="text-dark">{t("Catégorie")}: <span className="text-dark" style={{ textTransform: "capitalize", fontWeight: "bolder", color: "" }}>{isFrench ? roomType?.labelFr : roomType?.labelEn}</span></span>
    </div>
    <div>
      <div className="border-top border-dark p-1"></div>
    </div>
    <div className="d-block d-sm-block d-md-none pb-2">
      <button className="btn btn-sm btn-dark pl-4 pr-4" onClick={() => updateFocusDesc(true)}>
        <span className="icon-info"></span>
      </button>
    </div>
    <div className="d-sm-none d-md-block d-none">
      <p className="text-dark">{isFrench ? roomType?.descTextFr : roomType.descTextEn}</p>
      <span className="text-dark">{isFrench ? roomType?.descCapacityFr : roomType?.descCapacityEn}</span>
    </div>

  </div >

}


