import { IconEdit, Modal } from "@zipou/front_tools"
import { Device, DeviceInput } from "model"
import React, { useState } from "react"
import { DeviceAdd } from "../Add/DeviceAdd"
import { DeviceForm } from "../Form/DeviceForm"

type DeviceListProps = {
  deviceList: DeviceInput[],
  onChange: (deviceList: DeviceInput[]) => void,
}


export const DeviceList = ({ deviceList, onChange }: DeviceListProps) => {

  const [focusIndex, updateFocusIndex] = useState<number | null>(null)

  const hasDevice = deviceList?.length > 0

  return <div>
    {focusIndex !== null && <Modal title="Editer" display onClose={() => updateFocusIndex(null)}>
      <DeviceForm device={deviceList[focusIndex]} onChange={(deviceUpdate) => {
        onChange(
          deviceList?.map((el, idx) => idx === focusIndex ? deviceUpdate : el)
        )
      }} />
    </Modal>}
    <table className="table table-striped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          <td className="bg-dark text-white">Nom</td>
          <td className="bg-dark text-white">Actions</td>
        </tr>
      </thead>
      <tbody>
        {!hasDevice && <tr>
          <td colSpan={2}>
            <div className="alert alert-info">Rien à afficher</div>
          </td>
        </tr>}
        {deviceList?.map((device, idx) => {
          return <tr>
            <td>{device?.name}</td>
            <td>
              <button className="btn btn-sm btn-warning ml-1" onClick={() => {
                updateFocusIndex(idx)
              }}>
                <IconEdit />
              </button>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div>
      <button className="btn btn-dark btn-sm" onClick={() => {
        const newDeviveList = [
          ...(deviceList || []),
          {}
        ]
        onChange(newDeviveList);
        updateFocusIndex(newDeviveList?.length - 1)
      }}>Ajouter</button>
    </div>
  </div >




}
