import { useQuery } from "@apollo/react-hooks"
import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"
import { Proposition, PropositionPushPmsEnum, PropositionPushStatusEnum, PropositionRow, PropositionRowTotal, PropositionStatusEnum, ReservationOptionQuantityTypeEnum, UserRoleEnum } from "model"
import React, { useContext, useState } from "react"
import { PropositionStatus } from "../Status/PropositionStatus"
import { DateFormat, Modal, PriceFormat } from "@zipou/front_tools"
import { userContext } from "context/User"
import { PropositionPush } from "../Push/PropositionPush"
import { useHistory } from "react-router-dom"
import { URL_PROPOSITION_EDIT } from "constant/url"
import { RoomDetails } from "../../Room/Details/RoomDetails"
import { PaymentReceiptDisplay } from "components/front/BILLING/Payment/Receipt/PaymentReceiptDisplay"
import { PropositionSend } from "../Send/PropositionSend"
import { ContactEditButton } from "../../Contact/Widget/ContactEditButton"
import { PropositionPushStatusDisplay } from "../Push/Status/Display/PropositionPushStatusDisplay"

type PropositionDisplayProps = {
  id: string,
}

export const PropositionDisplay = ({ id }: PropositionDisplayProps) => {

  const [focusEmail, updateFocusEmail] = useState(false)
  const [displayPushModal, updateDisplayPushModal] = useState(false)

  const [focusRow, updateFocusRow] = useState<PropositionRow>()

  const history = useHistory()

  const { data } = useQuery<{ result: { warning: any, proposition: Proposition } }>(propositionGetGql, {
    variables: {
      id
    }
  })

  const proposition = data?.result?.proposition

  const isEditable = true
  const isEmailViewable = !!proposition?.Contact

  const user = useContext(userContext)
  const isAdmin = user?.roles.includes(UserRoleEnum.ROLE_ADMIN)
  const userHasPushRights = isAdmin || user?.roles?.includes(UserRoleEnum.ROLE_PMS_PROPOSITION_PUSH)
  const canPush = userHasPushRights &&
    proposition?.status === PropositionStatusEnum.STATUS_CONFIRMED &&
    proposition?.pushPms !== PropositionPushPmsEnum.PROPOSITION_PUSH_NONE

  const hasPayment = !!proposition?.Payment

  const rowSorted = [...(proposition?.propositionRow || [])]?.sort((el1, el2) => (el1?.id || "") === proposition?.rowSelectedId ? -1 : 1) || []
  const hasRowSelected = !!proposition?.rowSelectedId
  const [rowSelected, ...otherRows] = rowSorted
  const hasOthers = otherRows?.length > 0
  const hasContact = !!proposition?.Contact

  const renderRow = (row: PropositionRow) => {

    const propositionRowTotal = row?.totalRow

    const hasExtraDiscount = propositionRowTotal?.totalDiscountExtra > 0
    const hasHostingDiscount = propositionRowTotal?.totalDiscountHosting > 0

    const isSelected = row?.id === proposition?.rowSelectedId

    return <div className="card">
      <div className="card-header bg-primary text-white">
        Proposition de séjour
      </div>
      <div className="card-body">
        {isSelected && <div className="alert alert-success">
          Proposition selectionnée
        </div>}

        <table className="table table-striped table-bordered">
          <tbody>
            {row?.roomList?.map((room, idx) => {

              const hasOptions = room?.optionList?.length > 0

              return <tr key={`room_${idx}`}>
                <td>
                  <div className="card">
                    <div className="card-header bg-dark text-white">
                      Chambre {idx + 1}
                    </div>
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr>
                            <th>Nuits</th>
                            <td>
                              {room?.itemList?.map((item, itemIndex) => {
                                return <li className="list-group-item" key={`nights-${idx}-${itemIndex}`}>

                                  <DateFormat value={new Date(item.date)} />
                                  <span className="badge badge-primary ml-2">
                                    {item?.PriceRate?.PriceRateType?.labelFr}
                                  </span>
                                  <span className="badge badge-secondary ml-2">
                                    <RoomDetails roomId={item?.roomId} />
                                  </span>
                                </li>
                              })}
                            </td>
                          </tr>
                          {hasHostingDiscount && <tr>
                            <th>Réduction Hébergement</th>
                            <td>
                              {propositionRowTotal?.totalDiscountHosting} €
                            </td>
                          </tr>}
                          {hasOptions && <tr>
                            <th>Options</th>
                            <td>
                              {room?.optionList?.map((option, indexOption) => {
                                const hasDiscount = !!option?.discount
                                return <li className="list-group-item" key={`option_${idx}_${indexOption}`}>
                                  {option?.Option?.labelFr}
                                  <span className="badge badge-primary ml-2"> Quantité x{option?.quantity} {option?.quantityType === ReservationOptionQuantityTypeEnum.TYPE_PER_NIGHT ? " par nuit" : ""}</span>
                                  {hasDiscount && <span className="badge badge-info ml-1"> Réduction {option?.discount?.percent ? `${option?.discount?.percent}%` : `${option?.discount?.forced} €`}</span>}
                                </li>
                              })}
                            </td>
                          </tr>}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            })}
            <tr>
              <td>
                <div className="card">
                  <div className="card-header bg-dark text-white">
                    Total
                  </div>
                  <div className="card-body">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th>Hébergement</th>
                          <td>{hasHostingDiscount ? <span><span style={{ textDecoration: "line-through" }}>{propositionRowTotal?.totalHostingWithoutDiscount} €</span> - {propositionRowTotal?.totalHosting} €</span> : <span>{propositionRowTotal?.totalHosting} €</span>}</td>
                        </tr>
                        <tr>
                          <th>Extra </th>
                          <td>{hasExtraDiscount ? <span><span style={{ textDecoration: "line-through" }}>{propositionRowTotal?.totalExtraWithoutDiscount} €</span> - {propositionRowTotal?.totalExtra} €</span> : <span>{propositionRowTotal?.totalExtra} €</span>}</td>
                        </tr>
                        <tr>
                          <th>Général </th>
                          <td>{(propositionRowTotal?.totalExtra + propositionRowTotal?.totalHosting) || 0} €</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }



  return <div>
    {!!focusRow && <Modal display onClose={() => updateFocusRow(null)}>
      {renderRow(focusRow)}
    </Modal>}
    {focusEmail && <Modal display={focusEmail} onClose={() => updateFocusEmail(false)}>
      <PropositionSend id={proposition?.id} onDone={() => updateFocusEmail(false)} />
    </Modal>}
    {displayPushModal && <Modal display={true} onClose={() => updateDisplayPushModal(false)}>
      <PropositionPush propositionId={proposition?.id} onDone={() => updateDisplayPushModal(false)} />
    </Modal>}
    <h1>{proposition?.publicId}</h1>
    <div className="card">
      <div className="card-body">
        <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <th>Statut</th>
              <td>
                <PropositionStatus value={proposition?.status as PropositionStatusEnum} />
              </td>
            </tr>
            <tr>
              <th>Contact</th>
              <td>
                {!hasContact && <div className="badge badge-danger">Aucun Contact</div>}
                {hasContact && <ContactEditButton contact={proposition?.Contact} />}
              </td>
            </tr>
            <tr>
              <th>Push</th>
              <td>
                <PropositionPushStatusDisplay status={proposition?.pushStatus as PropositionPushStatusEnum} />
              </td>
            </tr>
            <tr>
              <th>Personnes</th>
              <td>
                <ul className="list-group">
                  <li className="list-group-item">Adultes: {proposition?.nbAdults || 0} </li>
                  <li className="list-group-item">Enfants: {proposition?.nbChildren || 0} </li>
                  <li className="list-group-item">Special: {proposition?.nbSpecial || 0} </li>
                </ul>
              </td>
            </tr>
            {hasPayment && <tr>
              <th>Paiement</th>
              <td>
                <PaymentReceiptDisplay publicId={proposition?.Payment?.publicId} salt={proposition?.Payment?.publicSalt} />
              </td>
            </tr>}
          </tbody>
        </table>
        {!hasRowSelected && <div className="row">
          {rowSorted?.map((row, idex) => {
            return <div key={`prop_${idex}`} className="col-6">{renderRow(row)}</div>
          })}
        </div>}
        {hasRowSelected && <div>
          <div className="">
            {renderRow(rowSelected)}
          </div>
          {hasOthers && <div className="card mt-2">
            <div className="card-header bg-secondary text-white">
              Autre(s) Proposition(s)
            </div>
            <div className="card-body">
              <div className="card-columns">
                {otherRows?.map((row, idx) => {
                  return <div className="card" key={`other_${idx}`}>
                    <div className="card-header">
                      #{idx + 1}
                    </div>
                    <div className="card-body">
                      <button className="btn btn-sm btn-info" onClick={() => updateFocusRow(row)}>Voir</button>
                    </div>
                  </div>

                })}
              </div>

            </div>
          </div>}
        </div>}
      </div>
      <div className="card-footer">
        {isEditable && <button className="btn btn-warning btn-sm ml-1" onClick={() => history.push(URL_PROPOSITION_EDIT.replace(":id", proposition?.id))}>Modifier</button>}
        {isEmailViewable && <button className="btn btn-dark btn-sm ml-1" onClick={() => updateFocusEmail(true)}>Voir l'email</button>}
        <button className="btn btn-sm btn-danger ml-1" disabled={!canPush} onClick={() => updateDisplayPushModal(true)} style={{ marginRight: 5 }}>Pusher</button>
      </div>
    </div>

  </div >

}