import { useMutation } from "@apollo/react-hooks"
import { CardCvcElement, CardExpiryElement, CardNumberElement, PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { PaymentMethodResult } from "@stripe/stripe-js"
import { Modal } from "@zipou/front_tools"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"

import tokenConfirmGql from "graphql/BILLING/Token/mutation/tokenConfirm.gql"
import { Token, TokenStatusEnum } from "model"
// import paymentUpdateGql from "graphql/BILLING/Token/subscription/paymentUpdate.gql"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export type TokenConfirmStripeProps = {
  token: Token,
  onCancel?: () => void,
}

export const TokenConfirmStripe = ({ token, onCancel }: TokenConfirmStripeProps) => {

  const urlParams = new URLSearchParams(window.location.search);
  const noWallet = urlParams.get('noWallet') === ""

  const [displayReceipt, updateDisplayReceipt] = useState(false)
  const [hasError, updateHasError] = useState(false)
  const [loading, updateLoading] = useState(false)
  const stripe = useStripe();
  const elements = useElements();

  const { t } = useTranslation('translation', { keyPrefix: 'billing' });

  const [mutate] = useMutation<{ token: Token }>(tokenConfirmGql)

  const onSubmit = (e: any) => {
    e.preventDefault()
    updateLoading(true)
    /** @ts-ignore */
    stripe?.createPaymentMethod({
      /** @ts-ignore */
      //eslint-disable-next-line
      type: "card",
      /** @ts-ignore */
      card: elements.getElement(CardNumberElement),
      /** @ts-ignore */
      //eslint-disable-next-line
      card: elements.getElement(CardExpiryElement),
      /** @ts-ignore */
      //eslint-disable-next-line
      card: elements.getElement(CardCvcElement),
    })
      .then((stripeResponse: PaymentMethodResult) => {

        const pmId = stripeResponse?.paymentMethod?.id
        return mutate({
          variables: {
            id: token?.id,
            options: {
              paymentMethodId: pmId,
            }
          }
        })
          .then(async (response) => {
            const payment = response?.data?.token
            switch (payment?.status) {

              case TokenStatusEnum.STATUS_PENDING_CUSTOMER: {
                const paymentOptions = token?.Options
                return stripe?.confirmCardSetup(paymentOptions.stripeClientSecret)
              }

              case TokenStatusEnum.STATUS_DONE: {
                return
              }

              default: {
                console.log("STATUS", payment?.status)
                return
              }
            }

          })
          .then(() => {
            updateLoading(false)
            console.log("DONE")
          })
          .catch((error: any) => {
            updateHasError(true)
            updateLoading(false)
            console.log("ERROR", error)
          })
      })
  }

  const isDisabled = (token?.status === TokenStatusEnum.STATUS_DONE || token?.isError || loading || token?.status === TokenStatusEnum.STATUS_DELETED) || false
  const isSuccess = token?.status === "STATUS_DONE"
  const isError = !!token?.isError || hasError
  const isDeleted = token?.status === "STATUS_DELETED"
  const Hotel = token?.Hotel

  const canDisplayReceipt = isSuccess && token?.receiptInfo

  return <div className="">
    <Modal display={displayReceipt} title={t("receipt")} onClose={() => updateDisplayReceipt(false)}>
      <table className="table table-stripped table-bordered">
        <tbody>
          <tr>
            <th>{t("id")}</th>
            <td>
              {token?.publicId}/{token?.publicSalt}
            </td>
          </tr>
          <tr>
            <th>{t("date")}</th>
            <td>
              <DateTimeFormat value={new Date(token?.captureDate)} />
            </td>
          </tr>
          <tr>
            <th>{t("card_brand")}</th>
            <td>
              {token?.receiptInfo?.brand}
            </td>
          </tr>
          <tr>
            <th>{t("card_last4")}</th>
            <td>
              XXXX-XXXX-XXXX-{token?.receiptInfo?.last4}
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
    <form onSubmit={onSubmit}>
      <div className="">
        {isSuccess && <div className="alert alert-success">
          <span>{t("token_ok")}</span>
          {canDisplayReceipt && <p>
            <button className="btn btn-sm btn-info mt-2" onClick={() => updateDisplayReceipt(true)}>{t("display_receipt")}</button>
          </p>}
        </div>}

        {(isError && !isSuccess) && <div>
          <div className="alert alert-danger">
            {t("error")}
            <div className="mt-2">
              <span>{Hotel?.name}</span><br />
              <span>{Hotel?.phoneNumber}</span>
            </div>
          </div>
        </div>}
        <div className="alert alert-warning">
          {t("token_warning")}
        </div>

        {isDeleted && <Modal display={true}>
          <div className="alert alert-danger">{t("link_expired")}</div>
          <div>
            <span>{Hotel?.name}</span><br />
            <span>{Hotel?.address}</span><br />
            <span>{Hotel?.phoneNumber}</span>
          </div>
        </Modal>}
        <div style={{ paddingTop: 5 }} >
          <h1>{t("confirm_resa_insert_info")}</h1>
        </div>
        <div className="row">

          <div className="card-number col-12">
            <div className="input-group">
              <span className="text-dark">{t("card_number")}</span>
              <CardNumberElement options={{ disabled: isDisabled }} className="stripe-cardnumber-element public-input" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="input-group">
              <span className="text-dark">{t("expiration")}</span>
              <CardExpiryElement options={{ disabled: isDisabled }} className="stripe-cardexpiry-element public-input" />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="input-group">
              <span className="text-dark">{t("crypto")}</span>
              <CardCvcElement options={{ disabled: isDisabled }} className="stripe-cardcvc-element public-input" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="row">
              <div className="col-12 col-sm-3 align-items-center pt-2 pt-sm-0">
                <button style={{ marginLeft: 10 }} className="btn btn-sm btn-dark" type="submit" disabled={isDisabled}>{loading && <span className="icon-loading"> </span>}{t("confirm")}</button>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 pt-2 pt-sm-0 d-flex justify-content-center align-items-center">
            <span><span style={{ fontSize: 12 }}>{t("secure")}</span><span className="icon-lock ml-1"></span></span>
          </div>
        </div>
      </div>
    </form>
  </div>

}