import gql from "graphql-tag";
import HotelPropositionFragment from "graphql/PMS/Hotel/fragment/HotelPropositionFragment";

// import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import PropositionRowFragmentGql from "../Row/fragment/PropositionRowFragment.gql";

export default gql`

  ${HotelPropositionFragment}
  ${PropositionRowFragmentGql}

  fragment PropositionPushFragment on Proposition {
    id
    publicId
    status
    pushPms
    pushStatus
    sentStatus
    rowSelectedId
    status
    contactId
    nbRooms
    nbAdults
    nbChildren
    nbSpecial
    isExpired
    expirationDate
    comment
    hotelId
    pushPms
    pushStatus
    Hotel {
      ...HotelPropositionFragment
    }
    propositionRow {
      ...PropositionRowFragment
    }
  }

`