import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks'
import propositionPushGql from 'graphql/PMS/Proposition/mutation/propositionPush.gql'
import propositionGetGql from 'graphql/PMS/Proposition/query/propositionGet.gql'
import propositionPushUpdateGql from 'graphql/PMS/Proposition/subscription/propositionPushUpdate.gql'
import { Proposition, PropositionPushPmsEnum, PropositionPushStatusEnum, PropositionRow, PropositionRowPaymentConditionEnum, PropositionRowRoomOption, UserRoleEnum } from 'model'
import React, { useContext, useEffect, useState } from 'react'
import { PropositionPushStatusDisplay } from './Status/Display/PropositionPushStatusDisplay'
import { userContext } from 'context/User'

type PropositionPushProps = {
  propositionId: string,
  onDone: () => void,
}

export const PropositionPush = ({ onDone, propositionId }: PropositionPushProps) => {

  const [error, updateError] = useState<string>(null)

  const { data } = useQuery<{ result: { proposition: Proposition } }>(propositionGetGql, {
    variables: {
      id: propositionId,
    },
    skip: !propositionId
  })

  const [mutate, { loading }] = useMutation<{ proposition: Proposition }>(propositionPushGql)

  const user = useContext(userContext)

  useSubscription(propositionPushUpdateGql, {
    variables: {
      id: propositionId
    },
    skip: !propositionId
  })

  const proposition: Proposition = data?.result?.proposition

  const propositionRow = proposition?.propositionRow?.find(el => el.id === proposition?.rowSelectedId)
  const pushPms = proposition?.pushPms
  const pushStatus = proposition?.pushStatus

  const onPush = (id: string) => {
    updateError(null);
    mutate({
      variables: {
        id,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        updateError(e.message)
      })
  }


  /**
   * 
   *  
      case PropositionPushStatusEnum.PUSH_NONE
      case PropositionPushStatusEnum.PUSH_RESERVATION_DONE
      case PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
      case PropositionPushStatusEnum.PUSH_OPTIONS_DONE
      case PropositionPushStatusEnum.PUSH_DEPOSIT_DONE
   * 
   */

  const resaPending = pushStatus === PropositionPushStatusEnum.PUSH_NONE || pushStatus === null
  const discountPending = resaPending || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE
  const optionsPending = discountPending || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const statusPending = optionsPending || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE

  const statusDone = pushStatus === PropositionPushStatusEnum.PUSH_CONFIRMED_DONE
  const optionsDone = statusDone || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE
  const discountDone = optionsDone || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const resaDone = discountDone || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE

  const hasDeposits = propositionRow?.paymentCondition === PropositionRowPaymentConditionEnum.CONDITION_10 ||
    propositionRow?.paymentCondition === PropositionRowPaymentConditionEnum.CONDITION_20 ||
    propositionRow?.paymentCondition === PropositionRowPaymentConditionEnum.CONDITION_30 ||
    propositionRow?.paymentCondition === PropositionRowPaymentConditionEnum.CONDITION_50 ||
    propositionRow?.paymentCondition === PropositionRowPaymentConditionEnum.CONDITION_100

  const mustConfirm = pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_POST_CONFIRMED

  const hasOptionsToPush = propositionRow?.roomList?.reduce((acc, rowRoom) => {
    return acc || rowRoom?.optionList?.length > 0
  }, false)


  const hasDiscounts = propositionRow?.roomList?.reduce((acc, rowRoom) => {
    return acc || !!rowRoom?.discount
  }, false)

  const canPush = pushStatus !== PropositionPushStatusEnum.PUSH_DONE

  return <div>
    <h1>Push Proposition {proposition?.publicId}</h1>
    {!!error && <div className='alert alert-danger'>
      {error}
    </div>}
    <h2>Status <PropositionPushStatusDisplay status={proposition?.pushStatus as PropositionPushStatusEnum} /></h2>

    <PushReservation propositionRow={propositionRow} />
    {hasDiscounts && <PushDiscount propositionRow={propositionRow} />}
    {hasOptionsToPush && <PushOptions propositionRow={propositionRow} />}
    {mustConfirm && <PushConfirmed propositionRow={propositionRow} />}
    {hasDeposits && <PushAhrres propositionRow={propositionRow} />}
    <div className='mt-2'>
      {canPush && <button disabled={!!loading} className='btn btn-sm btn-dark' onClick={() => onPush(proposition?.id)}>
        {loading && <span className='icon-loading mr-2'></span>} Pusher
      </button>}
    </div>
  </div >

}

export const PushReservation = ({ propositionRow }: { propositionRow: PropositionRow }) => {

  return <div className="card">
    <div className="card-header d-flex align-items-center justify-content-center bg-dark text-white">
      Push Reservation(s)
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered">
        <thead>
          <tr className='text-white bg-dark'>
            <td className='text-white bg-secondary'>Type</td>
            <td className='text-white bg-secondary'>Status</td>
          </tr>
        </thead>
        <tbody>
          {propositionRow?.roomList?.map((room, index) => {
            const sameConditions = room.sameConditions

            if (sameConditions) {

              const isPending = !room.pmsId
              const isPushed = !!room.pmsId

              return <tr key={`room_${index}`} >
                <td>Chambre {index + 1}</td>
                <td>
                  {isPushed && <>
                    <span className='badge badge-success ml-1'>
                      OK
                    </span>
                    <span className='badge badge-info ml-1'>#{room?.pmsId}</span>
                  </>}
                  {isPending && <span className='badge badge-sm badge-warning'>
                    En Attente
                  </span>}
                </td>
              </tr>

            } else {

              return room?.itemList?.map((item, idx) => {

                const isPending = !item.pmsId
                const isPushed = !!item.pmsId

                return <tr key={`room_${index}_${idx}`} >
                  <td>Reservation {index + 1}</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                      <span className='badge badge-info ml-1'>#{item?.pmsId}</span>
                    </>}
                    {isPending && <span className='badge badge-sm badge-warning'>
                      En Attente
                    </span>}
                  </td>
                </tr>
              })
            }
          })}
        </tbody>
      </table>
    </div>
  </div>

}

export const PushConfirmed = ({ propositionRow }: { propositionRow: PropositionRow }) => {

  return <div className="card">
    <div className="card-header d-flex align-items-center justify-content-center text-white bg-dark">
      Push Statut Confirmé
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered">
        <thead>
          <tr className='text-white bg-secondary'>
            <td className='text-white bg-secondary'>Type</td>
            <td className='text-white bg-secondary'>Status</td>
          </tr>
        </thead>
        <tbody>
          {propositionRow?.roomList?.map((room, index) => {

            // const roomPushState = pushState.roomList[index]
            const sameConditions = room.sameConditions

            if (sameConditions) {

              const isPending = !room.confirmedPush
              const isPushed = !!room.confirmedPush

              return <tr key={`room_${index}_push`}>
                <td>Chambre {index + 1}</td>
                <td>
                  {isPushed && <>
                    <span className='badge badge-success ml-1'>
                      OK
                    </span>
                    <span className='badge badge-info ml-1'>#{room?.pmsId}</span>
                  </>}
                  {isPending && <span className='badge badge-sm badge-warning'>
                    En Attente
                  </span>}
                </td>
              </tr>

            } else {

              return room?.itemList?.map((item, idx) => {

                // const itemPushState = roomPushState.itemList[idx]
                const isPending = !item.confirmedPush
                const isPushed = !!item.confirmedPush

                return <tr key={`room_${index}_push_${idx}`} className=''>
                  <td>Reservation {idx + 1}</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                      <span className='badge badge-info ml-1'>#{item?.pmsId}</span>
                    </>}
                    {isPending && <>
                      <span className='badge badge-sm badge-warning'>
                        En Attente
                      </span>
                    </>}
                  </td>
                </tr>
              })
            }
          })}
        </tbody>
      </table>
    </div>
  </div>

}


export const PushAhrres = ({ propositionRow }: { propositionRow: PropositionRow }) => {

  const room = propositionRow?.roomList[0]
  const pmsId = room?.pmsId || room?.itemList[0].pmsId
  const isPushed = !!room?.depositPushed
  const isPending = !isPushed

  return <div className="card">
    <div className="card-header d-flex align-items-center justify-content-center bg-dark text-white">
      Push Ahrres
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered">
        <thead>
          <tr className='text-white bg-secondary'>
            <td className='text-white bg-secondary'>Type</td>
            <td className='text-white bg-secondary'>Status</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reservation</td>
            <td>
              {isPushed && <>
                <span className='badge badge-success ml-1'>
                  OK
                </span>
                <span className='badge badge-info ml-1'>#{pmsId}</span>
              </>}
              {isPending && <span className='badge badge-sm badge-warning'>
                En Attente
              </span>}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

}

export const PushOptions = ({ propositionRow }: { propositionRow: PropositionRow }) => {

  return <div className="card">
    <div className="card-header d-flex align-items-center justify-content-center text-white bg-dark">
      Push Options(s)
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered">
        <tbody>
          {propositionRow?.roomList?.map((room, index) => {

            return <tr key={`option_tr_${index}} `}>
              <td>Resa {index + 1}</td>
              <td>
                <table className='table table-striped table-bordered'>
                  <tbody>
                    {room?.optionList?.map((option: PropositionRowRoomOption, idx) => {
                      const isPending = !option.optionPushed
                      const isPushed = !!option.optionPushed

                      return <tr key={`option_${index}_${idx}`} className=''>

                        <td>Option {option?.Option?.labelFr}.</td>
                        <td>
                          {isPushed && <>
                            <span className='badge badge-success ml-1'>
                              OK
                            </span>
                            {/* <span className='badge badge-info ml-1'>#{item?.pmsId}</span> */}
                          </>}
                          {isPending && <span className='badge badge-sm badge-warning'>
                            En Attente
                          </span>}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  </div>

}

export const PushDiscount = ({ propositionRow }: { propositionRow: PropositionRow }) => {

  return <div className="card">
    <div className="card-header d-flex align-items-center justify-content-center bg-dark text-white">
      Push Reductions(s)
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered">
        <thead>
          <tr className='text-white bg-secondary'>
            <td className='text-white bg-secondary'>Type</td>
            <td className='text-white bg-secondary'>Status</td>
          </tr>
        </thead>
        <tbody>
          {propositionRow?.roomList?.map((room, index) => {
            const sameConditions = room.sameConditions

            if (sameConditions) {

              const isPending = !room.discountPushed
              const isPushed = !!room.discountPushed

              return <tr key={`room_${index}`}>
                <td>Reservation #{room?.pmsId}</td>
                <td>
                  {isPushed && <>
                    <span className='badge badge-success ml-1'>
                      OK
                    </span>
                  </>}
                  {isPending && <span className='badge badge-sm badge-warning'>
                    En Attente
                  </span>}
                </td>
              </tr>

            } else {

              return room?.itemList?.map((item, idx) => {

                const isPending = !item.discountPushed
                const isPushed = !!item.discountPushed

                return <tr key={`room_${index}_${idx}`}>
                  <td>Reservation {idx + 1}</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                      <span className='badge badge-info ml-1'>#{item?.pmsId}</span>
                    </>}
                    {isPending && <span className='badge badge-sm badge-warning'>
                      En Attente
                    </span>}
                  </td>
                </tr>
              })
            }
          })}
        </tbody>
      </table>
    </div>
  </div>

}
